<template>
  <div class="challenges-details">
    <div class="panel">
      <div class="container">
        <router-link to="/challenges">
          <span class="text-left text-white"><i class="el-icon-arrow-left"></i>Back</span>
        </router-link>
        <span class="text-center text-white">Challenge details</span>
      </div>
    </div>
    <div
      v-if="!loading"
      class="challenge-info"
    >
      <div class="container">
        <div class="el-row">
          <el-col
            :xl="12"
            :lg="12"
            class="left-side"
          >
            <el-col
              :xl="24"
              :lg="24"
            >
              <div class="title">
                <p> {{ challenge.title }}</p>
              </div>
            </el-col>
            <el-col
              :xl="24"
              :lg="24"
            >
              <div
                v-if="challenge.status_text == 'Live' || challenge.status_text == 'Ended'"
                class="end-date"
              >
                <span class="date">{{ challenge.status_text == 'Live' ? 'Ends' : 'Ended' }}  {{ challenge.end_time_date }}</span>
                <span class="line">|</span>
                <span class="time">{{ challenge.end_time_time }}</span>
              </div>
              <div
                v-else-if="challenge.status_text == 'Scheduled'"
                class="end-date"
              >
                <span class="date">Ends  {{ challenge.start_time_date }}</span>
                <span class="line">|</span>
                <span class="time">{{ challenge.start_time_time }}</span>
              </div>
            </el-col>
            <!--Points-->
            <el-col
              :xl="24"
              :lg="24"
              class="points-title"
            >
              <span>
                Points
              </span>
            </el-col>
            <el-col
              v-if="challenge.points"
              :xl="24"
              :lg="24"
              class="points"
            >
              <div
                v-for="point in challenge.points.data"
                v-if="point.points !== 0"
                :key="point.id"
                class="point"
              >
                <vsvg-icon
                  data="@assetsIcon/star-icon.svg"
                  scale="1"
                  color="#ffffff"
                  class="star"
                />
                <span class="count">{{ point.points }}</span>
                <span class="title">{{ point.type_text }}</span>
              </div>
              <div
                v-if="challenge.target_type === 3"
                class="point"
              >
                <span class="star">&starf;</span>
                <span class="count">1</span>
                <span class="title">PT</span>
              </div>
            </el-col>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            class="right-side"
          >
            <el-col :xl="24">
              <div class="title">
                <span>What's it about?</span>
              </div>
            </el-col>
            <el-col
              :xl="24"
              :lg="24"
            >
              <div class="desc">
                <span>{{ challenge.description }}</span>
              </div>
            </el-col>
            <el-col
              v-if="challenge.external_url"
              :xl="24"
              :lg="24"
              class="external_url"
            >
              <a
                :href="challenge.external_url"
                target="_blank"
              >{{ externalUrlLabel }}</a>
            </el-col>
          </el-col>
        </div>
      </div>
    </div>
    <!--Header-->
    <div
      v-if="!loading"
      class="big-header"
    >
      <div
        v-if="challenge.banner_media_file"
        class="back"
        :style="{ 'background-image' : `url( ${ challenge.banner_media_file.large_thumb } )` }"
      >
        <div class="liner-grand"></div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="container"
    >
      <div class="more-info">
        <el-row>
          <el-col
            :xl="24"
            :lg="24"
          >
            <div
              v-if="challenge.target_type != 3"
              class="challenges_details-content-progress"
            >
              <el-progress
                :text-inside="false"
                :show-text="false"
                :stroke-width="18"
                :percentage="percentageNumber(challenge.target, challenge.entry_type_text == 'Self Enroll' ? challenge.user.logged_by_you : challenge.user.logged_by_all)"
                color="rgba(221, 0, 29, 1)"
              />
            </div>
          </el-col>
          <el-col
            v-if="challenge.target_type != 3"
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="12"
          >
            <div class="start-count">
              <p>0</p>
              <p v-if="challenge.target_type === 1">
                start
              </p>
              <p v-if="challenge.target_type === 2">
                activity
              </p>
            </div>
          </el-col>
          <el-col
            v-if="challenge.target_type != 3"
            :xl="12"
            :lg="12"
            :md="12"
            :sm="12"
            :xs="12"
          >
            <div class="finish">
              <p v-if="challenge.target_type === 1">
                {{ challenge.target }}
              </p>
              <p v-if="challenge.target_type === 2">
                {{ challenge.target }}
              </p>
              <p>finish</p>
            </div>
          </el-col>
          <el-col
            v-if="challenge.entry_type_text == 'Auto Enroll'"
            :xl="24"
            :lg="24"
          >
            <div class="team-card">
              <h2>Your team - {{ challenge.user.team.name }}</h2>
              <div
                v-if="challenge.user"
                class="results"
              >
                <el-row
                  type="flex"
                  justify="space-around"
                >
                  <el-col
                    :xl="6"
                    :lg="6"
                    :md="12"
                    :sm="24"
                  >
                    <p>{{ challenge.user.logged_by_team }}</p>
                    <span v-if="challenge.target_type === 1">Miles logged</span>
                    <span v-if="challenge.target_type === 2">Activity logged</span>
                    <span v-if="challenge.target_type === 3">Points logged</span>
                  </el-col>
                  <el-col
                    :xl="6"
                    :lg="6"
                    :md="12"
                    :sm="24"
                  >
                    <p>{{ challenge.user.team_users_contribute_count }}</p>
                    <span>Members contributed</span>
                  </el-col>
                  <el-col
                    :xl="6"
                    :lg="6"
                    :md="12"
                    :sm="24"
                  >
                    <p>{{ challenge.user.logged_by_you }}</p>
                    <span v-if="challenge.target_type === 1">Miles logged by you</span>
                    <span v-if="challenge.target_type === 2">Activities logged by you</span>
                    <span v-if="challenge.target_type === 3">Points logged by you</span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
          <el-col
            :xl="24"
            :lg="24"
          >
            <div class="leaderboard-table">
              <div class="title">
                <el-button
                  v-for="tab in tabs"
                  :key="tab.key"
                  :class="{'is-active': activeTab == tab.key}"
                  @click.native="activeTab = tab.key;"
                >
                  {{ tab.name }}
                </el-button>
              </div>
              <keep-alive>
                <team-leaderboard
                  v-if="activeTab == 'team-leaderboard'"
                  :challenge="challenge"
                ></team-leaderboard>
                <individual-leaderboard
                  v-if="activeTab == 'individual-leaderboard'"
                  :challenge="challenge"
                ></individual-leaderboard>
                <challenge-updates
                  v-if="activeTab == 'challenge-updates'"
                  :updates="challenge.posts.data"
                ></challenge-updates>
              </keep-alive>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="loading"
        v-loading="loading"
        class="container"
        style="min-height: 90px"
      ></div>
    </transition>
  </div>
</template>

<script>
import IndividualLeaderboard from '@/modules/challenge/components/individual-leaderboard'
import TeamLeaderboard from '@/modules/challenge/components/team-leaderboard'
import ChallengeUpdates from '@/modules/challenge/components/challenge-updates'
import challengeApi from '@/modules/challenge/services/challenge.api'

export default {
  name: "ChallengesDetailsPage",

  components: {
    IndividualLeaderboard,
    TeamLeaderboard,
    ChallengeUpdates,
  },

  data: () => ({
    loading: true,
    tabs: [],
    activeTab: '',
    challenge: null
  }),

  computed: {
    externalUrlLabel() {
      return this.challenge && this.challenge.external_url_label ? this.challenge.external_url_label : '> For further information, click here';
    },
  },

  created() {
    this.fetchDetails()
  },

  methods: {
    fetchDetails() {
      challengeApi
        .challenge
        .show(this.$route.params.id)
        .then(response => {
          this.loading = false
          this.challenge = response.data

          if (this.challenge.entry_type_text == "Auto Enroll") {
            this.tabs = [
              { key: 'team-leaderboard', name: 'Team leaderboard' },
              { key: 'individual-leaderboard', name: 'Individual leaderboard' },
              { key: 'challenge-updates', name: 'Challenge updates' }
            ]

            this.activeTab = 'team-leaderboard'
          } else {
            this.tabs = [
              { key: 'individual-leaderboard', name: 'Individual leaderboard' },
              { key: 'challenge-updates', name: 'Challenge updates' }
            ]

            this.activeTab = 'individual-leaderboard'
          }
        })
    },
    formatNumber(value) {
      let val = (value/1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatFinishNumber(val) {
      let str = val.split('.').join("")
      return str.slice(0, -2)
    },
    percentageNumber(percentage, number) {
      if (number == 0) {
        return 0
      }

      return number / percentage * 100
    }
  }
}
</script>

<style lang="scss">
.challenges-details {
  .panel {
    height: 55px;
    background-color: $feed-banner-primary-bg-color;
    width: 100%;
    .container {
      position: relative;
      .text-left {
        position: absolute;
        line-height: 55px;
      }
      .text-center {
        display: inline-block;
        line-height: 58px;
        width: 100%;
      }
    }
  }
  .challenge-info {
    background-color: #F0F3F5;
    padding: 36px 0 68px 0;
    .left-side {
      .title {
        p {
          margin: 0;
          font-size: 28px;
        }
      }
      .end-date {
        margin-top: 9px;
        color: #4A4A4A;
        font-size: 14px;
        .line, .time {
          margin-left: 8px;
        }
      }
      .points-title {
        margin-top: 29px;
        font-size: 12px;
      }
      .points {
        margin-top: 5px;
        .point {
          display: inline-block;
          height: 25px;
          line-height: 25px;
          background-color: $primary-color;
          padding: 0 20px 0 10px;
          border-radius: 6px;
          color: $white-color;
          margin-right: 5px;
          margin-bottom: 5px;
          &:last-child {
            margin-right: 0;
          }
          .star {
            font-size: 13px;
          }
          .count {
            font-size: 18px;
            margin-left: 5px;
            vertical-align: top;
          }
          .title {
            margin-left: 5px;
            font-size: 12px;
          }
        }
      }
    }
    .right-side {
      .title {
        font-size: 12px;
      }
      .desc {
        margin-top: 5px;
        font-size: 16px;
        p {
          margin: 24px 0 0 0;
          font-weight: bold;
        }
      }
      .external_url {
        margin-top: 20px;
      }
    }
  }
  .big-header {
    height: 462px;
    @media(max-width: 767px) {
      height: 245px;
    }
    .back {
      display: block;
      @media only screen and (max-width: 767px) {
        display: none;
      }
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      height: 462px;
      overflow: hidden;
      .liner-grand {
        position: absolute;
        left: 0%;
        right: 0%;
        //top: 52px;
        height: 100%;
        background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 6%, #000000 43%);
        background-blend-mode: multiply;
        mix-blend-mode: normal;
        opacity: 0.66;
      }

    }
    //.challenges_details-content-progress {
    //  position: absolute;
    //  top: 50%;
    //  width: 100%;
    //  padding: 0 84px;
    //}
  }

  .more-info {
    margin-top: -239px;
    margin-bottom: 239px;
    padding: 0 87px;
    @media (max-width: 767px) {
      padding: 0 10px;

    }
    .el-progress-bar {
      background: rgba(245, 245, 245, 0.18);
      //opacity: 0.18;
      padding: 7px;
      border-radius: 4px;
    }
    .el-progress-bar__outer {
      background-color: transparent;
      height: 30px !important;
      border-radius: 4px;
    }
    .el-progress-bar__inner {
      border-radius: 4px;
    }
    .start-count, .finish {
      margin-top: 19px;
      p {
        color: $white-color;
        font-weight: bold;
        font-size: 12px;
        margin: 0 0 4px 0;
        &:first-child {
          margin: 0;
          font-size: 20px;
          color: $primary-color;
        }
      }
    }
    .finish {
      text-align: right;
    }
    .team-card {
      background-color: $white-color;
      margin-top: 15px;
      padding: 19px 20px 37px 20px;
      border-radius: 4px;
      h2 {
        margin: 0 0 43px 0;
        @media (max-width: 767px) {
          text-align: center;
        }
      }
      .results {
        overflow: auto;
        text-align: center;
        p {
          margin: 0;
          font-size: 42px;
        }
        span {
          font-size: 13px;
        }
      }
    }
    .sort {
        display: inline-flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        float: right;
        line-height: 40px;
        @media (max-width: 767px) {
          align-items: end;
          display: block;
        }
      .txt {
        font-size: 12px;
        font-weight: bold;
        min-width: 40px;
        margin-right: 23px;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .drop-sel {
          margin-right: 10px;
          @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 15px;
          }
          .el-select {
            color: $primary-color;
            cursor: pointer;
            @media (max-width: 767px) {
              display: block;
            }
            input {
              color: $primary-color;
              border: none;
              border-radius: 0;
              border-bottom: 1px solid $border-color;
              padding: 0 6px 6px 6px;
              height: auto;
              line-height: normal;
              font-size: 13px;
              font-weight: bold;
            }
            i {
              margin-left: 90px;
              color: $primary-color;
              font-weight: 900;
              @media (max-width: 416px) {
                margin-left: 0;
                float: right;
              }
            }
          }
        }
      }
    .leaderboard-table {
      background-color: $white-color;
      margin-top: 12px;
      padding: 26px 20px 41px 20px;
      border-radius: 4px;
      .title {
        @media (max-width: 767px) {
          text-align: center;
        }
        button {
          background-color: $white-color;
          color: $primary-color;
          font-size: 18px;
          border-radius: 19.5px;
          padding: 10px 21px;
          border: none;
          &:hover {
            background-color: $primary-color;
            color: $white-color;
            -webkit-transition: all 0.5s ease-out;
            -moz-transition: all 0.5s ease-out;
            -o-transition: all 0.5s ease-out;
            transition: all 0.5s ease-out;
          }
          &:active {
            background-color: $primary-color;
            color: $white-color;
          }
          &.is-active {
            background-color: $primary-color;
            color: $white-color;
          }
          @media (max-width: 425px) {
            //font-size: 36px;
            //line-height: 40px;
          }
        }
      }
      table  {
        margin-top: 22px;
        width: 100%;
        border-collapse: collapse;
        &:before {
          display: none;
        }
        thead {
          th {
            background-color: $body-text-color;
            color: $white-color;
            padding: 8px 0 8px 7px;
            text-align: left;
            font-size: 12px;
            &:first-child {
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
            }
            &:last-child {
              text-align: right;
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              padding-right: 45px;
              @media (max-width: 320px) {
                padding-right: 0px;
                text-align: center;
              }
            }
            @media (max-width: 320px) {
              padding-right: 0px;
            }
          }
        }
        .table-loader {
          //left: 50%;
          //margin-right: 0%;
          //transform: translate(30%, 0%);
          &.el-loading-parent--relative {
            position: inherit !important;
            .el-loading-spinner {
              margin-top: 200px;
            }
          }
        }
        tbody {
          tr {
            font-size: 12px;
            &:nth-child(even) {
              background-color: #f9f9f9;
            }
            @media (max-width: 320px) {
              font-size: 12px;
            }
            td {
              border: none;
              font-size: 14px;
              padding: 9px 6px;
              text-align: left;
              &:last-child {
                text-align: right;
                padding-right: 42px;
                font-size: 25px;
                color: $primary-color;
                @media (max-width: 320px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    .leaderboard-table-pagination {
      text-align: center;
      margin: 57px 0;
    }
    .challenge-updates {
      overflow: auto;
      margin-top: 35px;
      .content {
        margin: 0 auto;
        float: none;
        overflow: auto;
      }
      .create-date-time {
        text-align: right;
        color: $primary-color;
        font-size: 14px;
      }
    }
  }
  .bottom-banner {
    background-color: $primary-color;
    height: 139px;
    line-height: 139px;
    display: flex;
    margin-bottom: 119px;
    @media (max-width: 767px) {
      display: block;
      height: auto;
    }
    .blocks {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        display: block;
        .get-rewarded, .complete-txt {
          text-align: center;
          margin: 10px 0;
        }
      }
      h2 {
        margin: 0;
        color: $white-color;
      }
      .challenge-complete {
        background-color: rgba(0, 0, 0, 0.59);
        width: 255px;
        height: 254px;
        margin: -59px auto;
        border-radius: 2px;
        @media (max-width: 767px) {
          margin: 0 auto;
        }
        .top {
          height: 150px;
        }
      }
    }
  }
}
</style>
