<template>
  <div class="challenge-updates">
    <div
      v-if="updates.length > 0"
      class="updates"
    >
      <el-col
        v-for="update of updates"
        :key="update.id"
        :xl="17"
        :lg="17"
        class="content"
      >
        <el-col
          :xl="24"
          :lg="24"
        >
          <p class="create-date-time">
            {{ update.created_at_date }} | {{ update.created_at_time }}
          </p>
        </el-col>
        <el-col
          :xl="24"
          :lg="24"
        >
          <span>{{ update.message }}</span>
        </el-col>
      </el-col>
    </div>
    <div
      v-else
      class="no-updates"
    >
      <el-col
        :xl="24"
        :lg="24"
      >
        <p class="text-center">
          There are currently no updates for this challenge. Please check again later.
        </p>
      </el-col>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChallengeUpdates",
  props: {
    updates: {
      type: Array,
      default: () => []
    }
  },
}
</script>