<template>
  <div class="individual-leaderboard">
    <table>
      <thead>
        <tr v-if="challenge.target_type === 1">
          <th>Individual</th>
          <th>Team</th>
          <th>Miles</th>
        </tr>
        <tr v-if="challenge.target_type === 2">
          <th>Individual</th>
          <th>Team</th>
          <th>Activities</th>
        </tr>
        <tr v-if="challenge.target_type === 3">
          <th>Individual</th>
          <th>Points</th>
        </tr>
      </thead>
      <transition name="fade">
        <div
          v-if="!rows.length"
          v-loading="loading"
          class="table-loader"
          style="min-height: 90px"
        ></div>
      </transition>
      <tbody v-if="rows">
        <tr
          v-for="(row, index) in rows"
          :key="index"
        >
          <td
            v-if="challenge.target_type === 1 || challenge.target_type === 2"
            class="text-left"
          >
            {{ row.name }}
          </td>
          <td
            v-if="challenge.target_type === 1 || challenge.target_type === 2"
            class="text-left"
          >
            {{ row.team }}
          </td>
          <td
            v-if="challenge.target_type === 1 || challenge.target_type === 2"
            class="text-right"
          >
            {{ row.total }}
          </td>

          <td
            v-if="challenge.target_type === 3"
            class="text-left"
          >
            {{ row.member }}
          </td>
          <td
            v-if="challenge.target_type === 3"
            class="text-right"
          >
            {{ row.total_points }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="leaderboard-table-pagination">
      <el-pagination
        v-if="pagination"
        :current-page.sync="pagination.current_page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import challengeApi from '@/modules/challenge/services/challenge.api'

export default {
  name: "ChallengeIndividualLeaderboard",

  props: {
    challenge: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    loading: false,
    rows: [],
    pagination: null,
    meta: {
      current_page: 1,
      per_page: 20,
    },
  }),

  created() {
    this.fetchLeaderboard()
  },

  methods: {
    fetchLeaderboard (customParams) {
      this.loading = true
      challengeApi
        .challenge
        .individualLeaderboard(this.challenge.id,
          _.assign({
            page: this.meta.current_page,
            per_page: this.meta.per_page
          }, customParams)
        )
        .then(response => {
          this.loading = false
          this.rows = response.data.data
          this.pagination = response.data.meta.pagination
        })
    },
    handleSizeChange(event) {
      this.fetchLeaderboard({ page: 1, per_page: event })
    },
    handleCurrentChange(event) {
      this.fetchLeaderboard({ page: event })
    },
  }
}
</script>
